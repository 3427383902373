<template>
	<b-container>
		<div class="header text-center">
			<div class="h1 mb-3">
				Template Builder
			</div>
			<div class="lead">
				An easy way to modify your templates for sharelink tracking
			</div>
		</div>
		<b-form class="w-100 text-left" @reset="files=[]">
			<b-form-group
				label="Template Upload"
			>
				<b-form-file
					v-model="templates"
					:state="Boolean(templates)"
					placeholder="Upload your template..."
					drop-placeholder="Drop file here..."
					multiple
					id="templates"
					accept=".html, .htm"
				>
				</b-form-file>
				<b-form-invalid-feedback :state="Boolean(templates)">
					Please upload one or more templates
				</b-form-invalid-feedback>
				<b-form-valid-feedback :state="Boolean(templates)">
					Noice!
				</b-form-valid-feedback>
			</b-form-group>
			<b-form-group
				label="Platform"
			>
				<b-form-select v-model="form.platform" :options="getSelectOpts('platforms')" :state="getState('platform')"></b-form-select>
				<b-form-invalid-feedback :state="getState('platform')">
					Please select your platform
				</b-form-invalid-feedback>
				<b-form-valid-feedback :state="getState('platform')">
					Looks good.
				</b-form-valid-feedback>
			</b-form-group>

			<b-form-group
				label="Template Engine"
				v-if="form.platform === 'mailtrain'"
			>
				<b-form-select v-model="form.engine" :options="getSelectOpts('templateEngines')" :state="getState('engine')"></b-form-select>
				<b-form-invalid-feedback :state="getState('engine')">
					Please select your template engine
				</b-form-invalid-feedback>
				<b-form-valid-feedback :state="getState('engine')">
					Oh, I see you template with extreme {{ ['proficiency', 'efficiency', 'beauty', 'grace', 'awesomeness'][Math.floor(Math.random() * (4 - 0 + 1)) + 0] }} ;)
				</b-form-valid-feedback>
			</b-form-group>

			<b-form-group
				label="Tracking URL"
			>
				<b-row>
					<b-col>
						<b-form-select v-model="form.url" :options="trackingOpts" :state="getState('url')"></b-form-select>
					</b-col>
					<b-col cols="auto">
						<b-button v-b-modal.add-url class="w-100" variant="primary">Add URL</b-button>
						<b-modal centered id="add-url" title="Add a Tracking URL" ok-variant="primary" @ok="addURL">
							<b-form-group
								label="Label"
							>
								<b-form-input v-model="url.text" type="text"></b-form-input>
							</b-form-group>
							<b-form-group
								label="URL"
							>
								<b-form-input v-model="url.value" type="text"></b-form-input>
							</b-form-group>
						</b-modal>
					</b-col>
				</b-row>
				<b-form-invalid-feedback :state="getState('url')">
					Please select your tracking domain
				</b-form-invalid-feedback>
				<b-form-valid-feedback :state="getState('url')">
					10-4, target acquired.
				</b-form-valid-feedback>
			</b-form-group>

			<b-form-group
				label="Tracking Link Version"
			>
				<b-form-select v-model="form.trackingVersion" :options="trackingVersionOpts" :state="getState('trackingVersion')"></b-form-select>
				<b-form-invalid-feedback :state="getState('trackingVersion')">
					Please select your tracking link version
				</b-form-invalid-feedback>
				<b-form-valid-feedback :state="getState('trackingVersion')">
					Link version activated!
				</b-form-valid-feedback>
			</b-form-group>

			<b-form-group
				label="Preheader Text"
			>
				<b-form-input v-model="form.preheader" placeholder="Enter your text here..."></b-form-input>
			</b-form-group>

			<b-form-group label="MetaData">
				<b-button variant="primary" v-if="!form.metadata.length" @click.prevent="addMetaData">Add MetaData</b-button>
				<template v-else>
					<b-input-group class="mb-2" v-for="(metadata, idx) in form.metadata" :key="metadata._id">
						<b-input-group-prepend is-text>
							<b-form-checkbox switch v-model="metadata.variable">
								Variable
							</b-form-checkbox>
						</b-input-group-prepend>
						<b-input-group-prepend>
							<b-form-input placeholder="Key" v-model="metadata.key" class="text-lowercase"></b-form-input>
						</b-input-group-prepend>
						<b-form-input placeholder="Val" v-model="metadata.val" v-if="!metadata.variable" class="text-lowercase"></b-form-input>
						<b-form-select v-model="metadata.val" :options="getSelectOpts('platformTags')" v-else></b-form-select>
						<b-form-input placeholder="Val" v-model="metadata.val_addon" v-if="metadata.variable && ['MERGE_', 'OTHER'].includes(metadata.val)" class="text-uppercase"></b-form-input>
						<b-input-group-append>
							<b-button variant="link" @click.prevent="deleteMetaData(idx)">
								<span class="material-icons-outlined">delete</span>
							</b-button>
						</b-input-group-append>
					</b-input-group>
				</template>
				<b-button variant="primary" v-if="form.metadata.length" @click.prevent="addMetaData">Add More MetaData</b-button>
			</b-form-group>

			<b-form-group label="Tracking Pixels">
				<b-form-tags
					id="tags-component-select"
					v-model="form.pixels"
					size="lg"
					class="mb-2 p-3"
					add-on-change
					no-outer-focus
				>
					<template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
						<ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
							<li v-for="tag in tags" :key="tag" class="list-inline-item">
							<b-form-tag
								@remove="removeTag(tag)"
								:title="tag"
								:disabled="disabled"
								:variant="tagVariant(tag)"
							>{{ tag }}</b-form-tag>
							</li>
						</ul>
						<b-form-select
							v-bind="inputAttrs"
							v-on="inputHandlers"
							:disabled="disabled || availablePixels.length === 0"
							:options="availablePixels"
							class="mb-2"
						>
							<template #first>
							<!-- This is required to prevent bugs with Safari -->
								<option disabled value="">Include another pixel...</option>
							</template>
						</b-form-select>
						<b-container fluid>
							<b-row align-h="between">
								<b-col>
									<small><b>Key:</b></small>
									<b-badge variant="secondary" class="ml-2">Default</b-badge>
									<b-badge variant="primary" class="ml-2">Custom</b-badge>
								</b-col>
								<b-col cols="auto">
									<b-button v-b-modal.add-pixel variant="primary">Add Custom Pixel</b-button>
								</b-col>
							</b-row>
						</b-container>
					</template>
				</b-form-tags>
				<b-modal centered id="add-pixel" title="Add a Tracking Pixel" ok-variant="primary" @ok="addPixel">
					<b-form-group
						label="Label"
					>
						<b-form-input v-model="pixel.label" type="text"></b-form-input>
					</b-form-group>
					<b-form-group
						label="HTML"
					>
						<b-form-input v-model="pixel.html" type="text"></b-form-input>
					</b-form-group>
					<b-alert show><b>Available Replacements in HTML:</b><br/>
					<ul><li>%email%</li><li>%campaign%</li><li>%pid%</li></ul></b-alert>
					<b-form-group
						label="Placement"
					>
						<b-form-select v-model="pixel.placement" class="mb-3">
							<b-form-select-option selected :value="null">Please select an option</b-form-select-option>
							<b-form-select-option value="head">Head</b-form-select-option>
							<b-form-select-option value="body">Body</b-form-select-option>
						</b-form-select>
					</b-form-group>
				</b-modal>
			</b-form-group>

			<b-form-group class="text-danger text-uppercase font-weight-bold" label="Unsubscribe">
				<div class="bg-white rounded border p-3">
					<b-form-checkbox v-model="form.unsubscribe" switch variant="danger">Add Unsubscribe Link</b-form-checkbox>
				</div>
			</b-form-group>

			<b-card no-body v-if="files.length" class="mb-3">
				<b-tabs pills card>
					<b-tab :title="file.name" v-for="(file, idx) in files" :key="file.name">
						<iframe :srcdoc="renderedTemplate(idx)" v-if="files.length" style="width: 100%; height: 600px;" />
					</b-tab>
				</b-tabs>
			</b-card>

			<div>
				<b-button type="submit" variant="primary" class="mr-2" @click.prevent="compileZip">Compile</b-button>
				<b-button type="reset" variant="primary-outline">Reset</b-button>
			</div>
		</b-form>
	</b-container>
</template>

<script>
import * as cheerio from 'cheerio';
import * as format from 'format-util';
import JsZip from 'jszip';
import { saveAs } from 'file-saver';
import moment from 'moment';
export default {
	name: 'Template',
	data () {
		return {
			templates: null,
			files: [],
			activeTemplate: false,
			form: {
				platform: null,
				engine: null,
				url: null,
				trackingVersion: null,
				tracking: {
					open: true,
					click: true
				},
				preheader: '',
				unsubscribe: false,
				metadata: [],
				pixels: [
					"Sharelink Opens",
					"Sharelink Clicks"
				]
			},
			metadataTemplate: {
				key: '',
				val: null,
				val_addon: '',
				variable: false
			},
			platforms: [
				{ value: 'mailtrain', text: 'Mailtrain' },
				{ value: 'puresend', text: 'Puresend' },
				{ value: 'smarttech', text: 'SmartTech' },
				{ value: 'activecampaign', text: 'Active Campaign' }
			],
			templateEngines: [
				{ value: 'simple', text: 'Simple [ VAR ]' },
				{ value: 'handlebars', text: 'Handlebars {{ VAR }}' },
				{ value: 'ejs', text: 'EJS <%= VAR %>' }
			],
			trackingURLs: [
				{ value: 'trk.sharelink.email', text: 'ShareLink' }
			],
			trackingVersions: [
				{ value: 'v1', text: 'v1' },
				{ value: 'v2', text: 'v2 - Beta with Limited Support' },
				{ value: 'v3', text: 'v3 - app.carriercrow.com' }
			],
			customTrackingURLs: [],
			customPixels: [],
			metaTypes: [
				{ value: 'static', text: 'Static' },
				{ value: 'variable', text: 'Variable' }
			],
			url: {},
			pixel: {}
		}
	},
	watch: {
		templates () {
			this.mountFile();
		}
	},
	computed: {
		enabledPixels () {
			return this.customPixels.concat(this.defaultPixels).filter(x => this.form.pixels.indexOf(x.label) > -1);
		},
		defaultPixels () {
			return [{label: "Sharelink Opens"}, {label: "Sharelink Clicks"}];
		},
		trackingOpts () {
			return [ { value: null, text: 'Please select one...' } ].concat(this.trackingURLs).concat(this.customTrackingURLs);
		},
		trackingVersionOpts () {
			return [ { value: null, text: 'Please select one...' } ].concat(this.trackingVersions);
		},
		Pixel () {
			return this.$FeathersVuex.api.Pixel;
		},
		URL () {
			return this.$FeathersVuex.api.URL;
		},
		availablePixels () {
			const opts = this.customPixels.concat(this.defaultPixels).filter(opt => this.form.pixels.indexOf(opt.label) === -1)
			return opts.map(x => {
				return { value: x.label, text: x.label}
			});
		},
		platformTags () {
			if (this.form.platform === 'mailtrain') {
				return [
					{ value: 'SUBSCRIPTION_ID', text: 'SUBSCRIPTION_ID' },
					{ value: 'LIST_ID', text: 'LIST_ID' },
					{ value: 'MERGE_', text: 'MERGE_' },
					{ value: 'OTHER', text: 'OTHER' }
				];
			}

			if (this.form.platform === 'puresend') {
				return [
					{ value: 'LISTID', text: 'LISTID' },
					{ value: 'EMAIL', text: 'EMAIL' },
					{ value: 'EMAILUSER', text: 'EMAILUSER' },
					{ value: 'EMAILDOMAIN', text: 'EMAILDOMAIN' },
					{ value: 'EMAILURLE', text: 'EMAILDOMAIN' },
					{ value: 'FROM', text: 'FROM' },
					{ value: 'SUBJECT', text: 'SUBJECT' },
					{ value: 'JOBNAME', text: 'JOBNAME' },
					{ value: 'OTHER', text: 'OTHER' }
				];
			}

			if (this.form.platform === 'activecampaign') {
				return [
					{ value: 'FIRSTNAME', text: 'FIRSTNAME' },
					{ value: 'LASTNAME', text: 'LASTNAME' },
					{ value: 'EMAIL', text: 'EMAIL' },
					{ value: 'PHONE', text: 'PHONE' },
					{ value: 'ACCT_NAME', text: 'ACCT_NAME' },
					{ value: 'CONTACT_JOBTITLE', text: 'CONTACT_JOBTITLE' },
					{ value: 'LIST_ID', text: 'LIST_ID' },
					{ value: 'OTHER', text: 'OTHER' }
				];
			}

			return [ { value: 'OTHER', text: 'OTHER' } ];
		}
	},
	methods: {
		tagVariant (tag) {
			if (["Sharelink Opens", "Sharelink Clicks"].includes(tag)) {
				return 'secondary'
			}
			return 'primary'
		},
		async addURL (e) {
			e.preventDefault();
			await this.url.save();
			this.$bvModal.hide('add-url');
			this.url = new this.URL;
			this.$root.$emit('bv::hide::modal', 'add-url', '#add-url');
			this.getCustomTrackingURLs();
		},
		getState (type) {
			let state = false;
			switch (type) {
				case 'platform': state = (this.form.platform != null);
				break;
				case 'engine': state = (this.form.engine != null);
				break;
				case 'url': state = (this.form.url != null);
				break;
				case 'trackingVersion': state = (this.form.trackingVersion != null);
			}

			return state;
		},
		compileZip () {
			const fileName = format('%s_templates_%s', this.form.platform, moment().format('YYYY-MM-DD'));
			const zip = new JsZip();
			const folder = zip.folder(fileName);
			for (let i=0; i<this.files.length; i++) {
				folder.file(this.files[i].name, this.renderedTemplate(i));
			}
			zip.generateAsync({type:"blob"})
			.then((blob) => {
				saveAs(blob, fileName + ".zip");
				this.$bvToast.toast(`${fileName}.zip has been downloaded!`, {
					title: 'File Saved',
					autoHideDelay: 5000,
					variant: 'info',
					appendToast: true
				})
			});
		},
		renderedTemplate (i) {
			const $this = this;
			const $ = cheerio.load(this.files[i].content, { _useHtmlparser2: true, decodeEntities: true });
			if (this.form.url && this.form.platform && (this.form.platform != "mailtrain" || this.form.engine)) {
				//handle sharelinks
				$('a').each(function () {
					const el = $(this);
					const sharelink = (typeof el.attr('sharelink') !== "undefined");
					if (sharelink) {
						const href = el.attr('href');
						const title = el.attr('title').replaceAll("'", '%27');
						const description = el.attr('description').replaceAll("'", '%27');
						const theme = el.attr('theme');
						const linkTpl = `${location.protocol}//${location.host}/share?url=${encodeURIComponent(href)}&title=${encodeURIComponent(title)}&description=${encodeURIComponent(description)}${theme ? `&theme=${encodeURIComponent(theme)}` : ''}`;
						el.attr('href', linkTpl);
					}
				});
				if (this.enabledPixels.length) {
					const { campaignIdTag, emailTag, pidTag } = this.getQueryParams();
					$('.___cp').remove();
					this.enabledPixels.forEach(pixel => {
						if (pixel.label == 'Sharelink Clicks') {
							$('a').each(function () {
								const el = $(this);
								const href = el.attr('href');
								const text = el.text();
								try {
									if (!href.match('UNSUB') && !text.match('unsub') && !href.match($this.form.url)) {
										el.attr('href', $this.trackLink(href));
										console.info('ADDING TRACKING LINK FOR:', href);
									}
								} catch (e) {
									console.error('FAILED TO ADD TRACKING LINK:', e.message, el);
								}
							});
						}
						else if (pixel.label == 'Sharelink Opens') {
							if(!$('.___tp').length) {
								$('body').append(
									format(
										'<img class="___tp" src="%s" style="position: absolute !important; bottom: 0px !important; height:1px !important; width:1px !important; border: 0 !important; margin: 0 !important; padding: 0 !important" width="1" height="1" border="0" />',
										this.getOpenPixel()
									)
								);
							}
						} else {
							let { html, placement } = pixel;
							html = html.replace('%email%',  emailTag).replace('%campaign%', campaignIdTag).replace('%pid%', pidTag);
							if (placement === 'head') {
								$(html).addClass('.___cp').appendTo('head');
							}
							if (placement === 'body') {
								$(html).addClass('.___cp').appendTo('body');
							}
						}
					});
				}
				if (this.form.preheader.length && !$('.___ph').length) {
					$('body').prepend(this.getPreheaderBlock());
				}
				if (this.form.unsubscribe) {
					let pass = true;
					$('a').each(function () {
						if ($(this).text().toLowerCase().match('unsub') || $(this).attr('href').toLowerCase().match('unsub')) {
							pass = false;
						}
					});
					if (pass) {
						$('body').append(format('<br /><div style="padding: 10px; text-align: center; font-size: 15px;">%s</div>', this.getUnsubLink()));
					}
				}
			}
			return $.html({ decodeEntities: true });
		},
		getSelectOpts (type) {
			return [ { value: null, text: 'Please select one...' } ].concat(this[type]);
		},
		async addPixel (e) {
			e.preventDefault();
			await this.pixel.save();
			this.$bvModal.hide('add-pixel');
			this.pixel = new this.Pixel;
			this.$root.$emit('bv::hide::modal', 'add-pixel', '#add-pixel');
			this.getCustomPixels();
		},
		addMetaData () {
			const metadata = JSON.parse(JSON.stringify(this.metadataTemplate));
			metadata._id = Date.now();
			this.form.metadata.push(metadata);
		},
		deleteMetaData (idx) {
			this.form.metadata.splice(idx, 1);
		},
		mountFile () {
			this.files = [];
			const files = document.getElementById("templates").files;
			for (let file of files) {
				const reader = new FileReader();
				reader.readAsText(file, "UTF-8");
				reader.onload = (evt) => {
					this.files.push({name: file.name, content: evt.target.result});
				}
				reader.onerror = function (evt) {
					console.log(evt);
				}
				this.$bvToast.toast(`${file.name} Added!`, {
					title: 'Template Uploaded',
					autoHideDelay: 5000,
					appendToast: true,
					variant: 'success'
				})
			}
			this.activeTemplate = 0;
		},
		trackLink (href) {
			const { campaignIdTag, emailTag, pidTag } = this.getQueryParams();
			const url = new URL(href);
			const search = href.split('?')[1];

			const links = {
				v1: '%s//%s/email-click/%s%s',
				v2: '%s//%s/redirect/' +
					(
						this.form.engine == 'ejs' ?
						"<%- Buffer.from(list.EMAIL).toString('base64') %>"
						: this.tagType(this.getTagType(), 'MERGE_EMAIL_ENCODED')
					) +
					'/%s%s',
				v3: `%s//%s/redirect/${this.tagType(this.getTagType(), 'EMAIL_BASE64')}/%s%s`
			};
			let link = format(links[this.form.trackingVersion], url.protocol, this.form.url, url.host, url.pathname);
			link = search ? link + '?' + search + '&' : link + '?';
			link = format('%s__meta[email]=%s&__meta[id]=%s&__meta[pid]=%s', link, emailTag, campaignIdTag, pidTag);
			if (this.form.metadata.length) {
				for (let meta of this.form.metadata) {
					link += format('&__meta[%s]=%s', meta.key.toLowerCase(), meta.variable ? this.tagType(this.getTagType(), meta.val + meta.val_addon.toUpperCase()) : meta.val.toLowerCase());
				}
			}
			return link;
		},
		getOpenPixel () {
			const { campaignIdTag, emailTag, pidTag } = this.getQueryParams();
			let link = format('https://%s/email-open/%s?email=%s&pid=%s', this.form.url, campaignIdTag, emailTag, pidTag);
			if (this.form.metadata.length) {
				for (let meta of this.form.metadata) {
					if ( meta.key && meta.val) {
						link += format('&%s=%s', meta.key.toLowerCase(), meta.variable ? this.tagType(this.getTagType(), meta.val + meta.val_addon.toUpperCase()) : meta.val.toLowerCase());
					}
				}
			}
			return link;
		},
		getQueryParams () {
			let campaignIdTag, emailTag, pidTag;
			if (this.form.platform === 'smarttech') {
				campaignIdTag = this.tagType('simple', 'CAMPAIGN_ID');
				emailTag = this.tagType('simple', 'EMAIL');
				pidTag = this.tagType('simple', 'PID');
			}
			if (this.form.platform === 'mailtrain') {
				campaignIdTag = this.tagType(this.form.engine, 'CAMPAIGN_ID');
				emailTag = this.tagType(this.form.engine, 'EMAIL');
				pidTag = this.tagType(this.form.engine, 'MERGE_PID');
			}
			if (this.form.platform === 'puresend') {
				campaignIdTag = this.tagType(this.form.platform, 'JOBID');
				emailTag = this.tagType(this.form.platform, 'EMAIL');
				pidTag = this.tagType(this.form.platform, 'PID');
			}
			if (this.form.platform === 'activecampaign') {
				campaignIdTag = this.tagType(this.form.platform, '');
				emailTag = this.tagType(this.form.platform, 'EMAIL');
				pidTag = this.tagType(this.form.platform, 'PID');
			}
			return { campaignIdTag, emailTag, pidTag };
		},
		getTagType () {
			let type;
			switch (this.form.platform)
			{
				case 'smarttech': type = 'simple';
				break;
				case 'mailtrain': type = this.form.engine;
				break;
				case 'activecampaign': type = this.form.platform;
				break;
				case 'puresend': type = this.form.platform;
				break;
				default: type = 'simple';
			}
			return type;
		},
		tagType (type, value) {
			let template;
			switch (type)
			{
				case 'simple': template = '[%s]';
				break;
				case 'handlebars': template = '{{%s}}';
				break;
				case 'ejs': template = '<%= list.%s %>';
				break;
				case 'puresend': template = '$%s$';
				break;
				case 'smarttech': template = '[%s]';
				break;
				case 'activecampaign': template = '%%' + value + '%%';
				break;
			}

			if (type === 'activecampaign') {
				return template;
			}

			return format(template, value);
		},
		getPreheaderBlock () {
			return `<div class="___ph"
						style="display:none !important;
						visibility:hidden;
						mso-hide:all;
						font-size:1px;
						color:#ffffff;
						line-height:1px;
						max-height:0px;
						max-width:0px;
						opacity:0;
						overflow:hidden;"
					>${this.form.preheader} &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </div>`;
		},
		getUnsubLink () {
			if (this.form.platform === 'smarttech') {
				return format('<a href="%s">unsubscribe</a>', this.tagType('simple', 'UNSUBSCRIBEURL'));
			}

			if (this.form.platform === 'mailtrain') {
				return format('<a href="%s">unsubscribe</a>', this.tagType(this.form.engine, 'LIST_UNSUBSCRIBE'));
			}

			if (this.form.platform === 'puresend') {
				return format('<a href="%s">unsubscribe</a>', this.tagType(this.form.platform, 'OPTOUT'));
			}

			if (this.form.platform === 'activecampaign') {
				return '<a href="%UNSUBSCRIBELINK%">unsubscribe</a>';
			}
		},
		getCustomTrackingURLs () {
			this.URL.find().then(res => {
				this.customTrackingURLs = res.data;
			});
		},
		getCustomPixels () {
			this.Pixel.find().then(res => {
				this.customPixels = res.data;
			});
		}
	},
	mounted () {
		this.url = new this.URL;
		this.pixel = new this.Pixel;
		this.getCustomTrackingURLs();
		this.getCustomPixels();
	}
}
</script>

<style lang="scss" scoped>

.container {
	max-width: var(--container-sm);
	margin: auto;
	padding: 0 1.5rem 4rem;
}

.header {
	margin: 4rem 0;
}

.form-group {
	margin-bottom: 1.5rem;
}

.input-group-prepend {
	.input-group-text {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		height: 100% !important;
		border: 1px solid 1px solid #ced4da !important;
	}
	.form-control {
		border-radius: 0 !important;
		height: calc(1.5em + 0.75rem + 2px) !important;
	}
}

.input-group-text {
	.custom-switch {
		display: flex;
		align-items: center;
	}
}

.input-group-append {
	.btn {
		padding: 0;
		height: 100%;
		display: flex;
		align-items: center;
		margin-left: 1rem;
	}
	.material-icons-outlined {
		font-size: 1.25rem !important;
	}
}

.custom-select {
	font-size: 14px;
}

.col-form-label {
	color: var(--color-dark-blue) !important;
	opacity: 0.5 !important;
}

	iframe {
		border: none;
	}

	.tab-pane {
		padding: 0;
	}
</style>
